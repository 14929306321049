import React from 'react';
import { DefaultRootState, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import MenuIcon from 'assets/icons/Menu';
import {
  checkUserExpire,
  isMobile,
  shouldShowLocationSubmenuSidebar,
} from 'utils/helper';
import { changeLocationSubMenuStatus } from 'store/pageAction/pageAction';
import useStyles from './styles';
import { SDS_HOME_PAGE } from 'constant';
import PushPinIcon from '@mui/icons-material/PushPin';
import {
  setExpandMenu,
  setIsPinned,
  setOpenDrawer,
} from 'services/common/slice';
import { useHistory } from 'react-router-dom';

const Logo = ({
  showMore,
  openDrawer,
  isPinned,
  user,
}: {
  showMore: boolean;
  openDrawer: DefaultRootState;
  isPinned: boolean;
  user: any;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const path = window.location.pathname;
  const classes = useStyles();
  const openMoreUrl = history.location.search.includes('open_more');

  const toggleDrawer = (open: boolean) => () => {
    dispatch(setExpandMenu(true));
    dispatch(setOpenDrawer(open));
  };

  const onShowLessClick = () => {
    if (!user || checkUserExpire(user)) return;
    dispatch(setIsPinned(false));
    if (openDrawer) dispatch(setOpenDrawer(false));
    dispatch(setExpandMenu(false));

    if (shouldShowLocationSubmenuSidebar(path)) {
      dispatch(changeLocationSubMenuStatus(true));
    }
  };

  React.useEffect(() => {
    if (openMoreUrl) {
      dispatch(changeLocationSubMenuStatus(false));
      dispatch(setExpandMenu(true));
    }
  }, [openMoreUrl]);

  if (isMobile()) {
    return (
      <Button onClick={toggleDrawer(true)} variant="text">
        <MenuIcon color={'black'} />
      </Button>
    );
  }

  if (showMore || checkUserExpire(user)) {
    return (
      <div className={classes.logoMax}>
        <img
          alt="Icon"
          src={'/images/logo.svg'}
          className={classes.logoImg}
          onClick={() => {
            if (!isPinned) {
              onShowLessClick();
              return;
            }
            history.push('/');
          }}
        />
        <div
          style={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'flex-end',
            paddingRight: 10,
            alignItems: 'center',
          }}
        >
          {showMore && isPinned && (
            <img
              alt="Icon"
              src={'/images/vector-close.svg'}
              className={classes.showMoreIcon}
              style={{
                paddingRight: !showMore ? 15 : 0,
              }}
              onClick={onShowLessClick}
            />
          )}
          {showMore && !isPinned && (
            <PushPinIcon
              sx={{
                fontSize: '16px',
                color: 'colors.primary',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(setIsPinned(true));
              }}
            />
          )}
        </div>
      </div>
    );
  }
  if (!user) {
    return (
      <div className={classes.logoMin}>
        <a href={SDS_HOME_PAGE}>
          <img alt="Icon" src={'/images/logo-min.svg'} style={{ height: 33 }} />
        </a>
      </div>
    );
  }

  return (
    <div
      className={classes.logoMin}
      onClick={() => {
        dispatch(changeLocationSubMenuStatus(false));
        dispatch(setExpandMenu(true));
      }}
    >
      <img alt="Icon" src={'/images/logo-min.svg'} style={{ height: 33 }} />
      {user && !checkUserExpire(user) && (
        <img alt="Icon" src={'/images/vector-open.svg'} style={{ width: 10 }} />
      )}
    </div>
  );
};

export default Logo;
