import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { featureNotAvailableSelector } from 'services/common/selectors';
import {
  setFeatureNotAvailable,
  setShowTrialPlanToolTip,
} from 'services/common/slice';
import { userDataSelector } from 'services/user/selectors';
import {
  openVisionPermissionSelector,
  planPermissionSelector,
} from 'services/permission/selectors';
import { AppDispatch } from 'constant';
import { SUBSCRIPTION_PLAN_LABEL } from 'enums/subscription-plan.enum';
import { SOLUTION_ID_MAPPER } from 'constant/faq-link';
/* Components */
import { Button, Typography, ClassNameMap } from '@mui/material';
import DesktopDialog from 'components/commons/desktop-dialog';
import { ReactElement } from 'react';

const FeatureNotAvailablePopup = () => {
  const { t } = useTranslation([
    'featureNotAvailablePopup',
    'upgradePlanSizePopup',
  ]);
  const history = useHistory();
  const classes: ClassNameMap = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(userDataSelector);
  const featureNotAvailable = useSelector(featureNotAvailableSelector);
  const planPermission = useSelector(planPermissionSelector);
  const openVisionPermission = useSelector(openVisionPermissionSelector);
  const isTrial = user?.customer.inventory_subscription_plan.is_trial_period;

  const onClose = (): void => {
    dispatch(setFeatureNotAvailable(null));
  };

  const gotoSubscriptionPage = (): void => {
    dispatch(setShowTrialPlanToolTip(true));
    history.push('/administration/subscription');
    onClose();
  };

  const getUpgradePlan = (): SUBSCRIPTION_PLAN_LABEL | null => {
    if (!featureNotAvailable) return null;
    if (!planPermission) return null;
    if (!openVisionPermission) return null;
    if (!openVisionPermission.includes(featureNotAvailable)) return null;

    const plan_list = Object.values(SUBSCRIPTION_PLAN_LABEL);
    for (let item in plan_list) {
      if (planPermission[plan_list[item]].includes(featureNotAvailable))
        return plan_list[item];
    }

    return null;
  };

  const getLinkFaq = (): ReactElement | null => {
    const fadLinks = Object.keys(SOLUTION_ID_MAPPER);
    if (!featureNotAvailable) return null;
    if (fadLinks.includes(featureNotAvailable)) {
      return (
        <Typography
          dangerouslySetInnerHTML={{
            __html: t('featureNotAvailablePopup:click_here_to_read_more', {
              solutionId: SOLUTION_ID_MAPPER[featureNotAvailable],
              classTextLink: classes.textLink,
            }),
          }}
        />
      );
    }
    return null;
  };

  const upgradePlanName = getUpgradePlan();

  return (
    <DesktopDialog
      open={!!featureNotAvailable}
      onClose={() => onClose()}
      titleElement={<div style={{ height: 10 }}></div>}
      classes={{ paper: classes.popup }}
    >
      <div className={classes.contentWrapper}>
        <div className={classes.imageWrapper}>
          <img
            src="/images/reset_trial_image.jpg"
            width={'100%'}
            height={'auto'}
          />
        </div>
        <div className={classes.textWrapper}>
          <div>
            <Typography style={{ fontWeight: 600, marginBottom: 15 }}>
              {isTrial
                ? t(
                    'featureNotAvailablePopup:this_feature_is_not_available_on_trial'
                  )
                : t(
                    'featureNotAvailablePopup:this_feature_is_not_available_on_current'
                  )}
              {}
            </Typography>
            {getLinkFaq()}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img alt="Icon" src={'/images/logo.svg'} style={{ width: 120 }} />
            </div>
          </div>
          <Typography>
            <Trans
              ns={'featureNotAvailablePopup'}
              i18nKey={
                isTrial ? 'your_current_trial_plan_is' : 'your_current_plan_is'
              }
              values={{
                planName: user?.customer.inventory_subscription_plan.plan.label,
              }}
              components={[
                <span
                  key="plan_name"
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                  }}
                />,
              ]}
            />
          </Typography>
          {upgradePlanName && (
            <Typography>
              <Trans
                ns={'featureNotAvailablePopup'}
                i18nKey={
                  isTrial ? 'you_need_to_change_to' : 'you_need_to_upgrade_to'
                }
                values={{
                  planName: upgradePlanName.split('_').join(' '),
                }}
                components={[
                  <span
                    key="plan_name"
                    style={{
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                    }}
                  />,
                ]}
              />
            </Typography>
          )}
          {user?.is_owner && !user?.customer?.parent_customer ? (
            <div>
              <Button variant="contained" onClick={gotoSubscriptionPage}>
                {isTrial
                  ? t('upgradePlanSizePopup:showOptions')
                  : t('upgradePlanSizePopup:upgradeButton')}
              </Button>
            </div>
          ) : (
            <Typography>
              {t('upgradePlanSizePopup:contactAdministrator')}
            </Typography>
          )}
        </div>
      </div>
    </DesktopDialog>
  );
};

export default FeatureNotAvailablePopup;
