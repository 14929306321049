import { createSlice } from '@reduxjs/toolkit';
import {
  PLAN_PERMISSION,
  LOCATION_ACCESS_PERMISSION,
  UserPermission,
} from 'interfaces/permission';
import { fetchPermission } from './thunks';
import { PERMISSION } from 'enums/permissions.enums';

export interface PermissionState {
  planPermission: PLAN_PERMISSION | null;
  locationAccessPermission: LOCATION_ACCESS_PERMISSION | null;
  openVisionPermission: Array<PERMISSION> | null;
}

const initialState: PermissionState = {
  planPermission: null,
  locationAccessPermission: null,
  openVisionPermission: null,
};

const PermissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      /* fetchPermission */
      .addCase(
        fetchPermission.fulfilled,
        (
          state: PermissionState,
          action: {
            payload: UserPermission;
          }
        ) => {
          state.planPermission = action.payload.plan_permission;
          state.openVisionPermission = action.payload.open_vision_permission;
          state.locationAccessPermission =
            action.payload.location_access_permission;
        }
      );
  },
});

export default PermissionSlice.reducer;
