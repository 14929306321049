export const weightMeasurements = [
    { value: 'ug', label: 'ug (micrograms)', system: 'metric' },
    { value: 'mg', label: 'mg (milligram)', system: 'metric' },
    { value: 'g', label: 'g (gram)', system: 'metric' },
    { value: 'kg', label: 'kg (kilogram)', system: 'metric' },
    { value: 'oz', label: 'oz(ounce)', system: 'us' },
    { value: 'lbs', label: 'lbs(pounds)', system: 'us' },
  ];
  
export const volumeMeasurements = [
    { value: 'ul', label: 'ul (microliter)', system: 'metric' },
    { value: 'ml', label: 'ml (milliliter)' , system: 'metric' },
    { value: 'l', label: 'l (liter)', system: 'metric' },
    { value: 'cc', label: 'cc (cubic centimeter)', system: 'metric' },
    { value: 'm3', label: 'm3 (cubic meter)', system: 'metric' },
    { value: 'fl oz', label: 'fl oz (fluid ounce)', system: 'us' },
    { value: 'pt', label: 'pt(pint)', system: 'us' },
    { value: 'qt', label: 'qt (quart)', system: 'us' },
    { value: 'gals', label: 'gals (gallons)', system: 'us' },
    { value: 'cu ft', label: 'cu ft (cubic foot)', system: 'us' },
  ];