import { GetStatisticsResponseDto } from 'interfaces/home';
import { fetchStatistics } from './thunks';
import { createSlice } from '@reduxjs/toolkit';

export interface HomeState {
  statistics: GetStatisticsResponseDto | null;
  statisticsLoading: boolean;
  hideAllPanels: boolean;
}

const initialState: HomeState = {
  statistics: null,
  statisticsLoading: false,
  hideAllPanels: false,
};

const HomeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHideAllPanels(state, action: { payload: boolean }) {
      state.hideAllPanels = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchStatistics.pending, (state: HomeState) => {
        state.statisticsLoading = true;
      })
      .addCase(
        fetchStatistics.fulfilled,
        (state: HomeState, action: { payload: GetStatisticsResponseDto }) => {
          state.statistics = action.payload;
          state.statisticsLoading = false;
        }
      )
      .addCase(fetchStatistics.rejected, (state: HomeState) => {
        state.statistics = null;
        state.statisticsLoading = false;
      });
  },
});

export const { setHideAllPanels } = HomeSlice.actions;
export default HomeSlice.reducer;
