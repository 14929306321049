import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPermission } from 'api/auth/get';
import { PERMISSION } from 'enums/permissions.enums';
import { ApiError, ErrorPayload } from 'interfaces/common/ApiResponseInterface';
import {
  GetPermissionResponse,
  GetPermissionResponseDto,
  LOCATION_ACCESS_PERMISSION,
  PLAN_PERMISSION,
  UserPermission,
} from 'interfaces/permission';
import aes128EcbDecrypt from 'utils/decryptionUtils';

export const fetchPermission = createAsyncThunk<
  UserPermission,
  undefined,
  {
    rejectValue: ErrorPayload;
  }
>('permission/fetchPermission', async (_, { rejectWithValue }) => {
  const response: GetPermissionResponse | ApiError = await getPermission();

  if (response.status !== 200)
    return rejectWithValue(response.data as ErrorPayload);

  const responseData: GetPermissionResponseDto =
    response.data as GetPermissionResponseDto;

  const planPermissionCipher = aes128EcbDecrypt(responseData.plan_permission);
  const planPermissionRaw: PLAN_PERMISSION = JSON.parse(
    planPermissionCipher.replaceAll("'", '"')
  );
  const locationAccessPermissionCipher = aes128EcbDecrypt(
    responseData.location_access_permission
  );
  const locationAccessPermissionRaw: LOCATION_ACCESS_PERMISSION = JSON.parse(
    locationAccessPermissionCipher.replaceAll("'", '"')
  );
  const openVisionPermissionCipher = aes128EcbDecrypt(
    responseData.open_vision_permission
  );
  const openVisionPermissionRaw: Array<PERMISSION> = JSON.parse(
    openVisionPermissionCipher.replaceAll("'", '"')
  );

  const data: UserPermission = {
    plan_permission: planPermissionRaw,
    location_access_permission: locationAccessPermissionRaw,
    open_vision_permission: openVisionPermissionRaw,
  };
  return data;
});
