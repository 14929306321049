import { memo, CSSProperties, MouseEvent } from 'react';
import useStyles from './styles';
import { Avatar as AvatarMui, ClassNameMap } from '@mui/material';
import { getMediaUrl } from 'utils/helper';

interface UserAvatar {
  avatar?: string | null;
  first_name?: string;
}

interface AvatarProps {
  user: UserAvatar | null;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  style?: CSSProperties;
  size?: number;
}

const Avatar = memo(
  ({ user, onClick = () => {}, style, size = 30 }: AvatarProps) => {
    const classes: ClassNameMap = useStyles();

    if (!user) return null;

    if (user.avatar) {
      return (
        <AvatarMui
          onClick={
            onClick !== undefined
              ? (e: MouseEvent<HTMLDivElement>) => onClick(e)
              : () => {}
          }
          style={{
            height: size,
            width: size,
            objectFit: 'contain',
            border: '1px solid #efebeb',
            marginRight: 5,
            ...style,
          }}
          src={getMediaUrl(user.avatar)}
        />
      );
    }

    return (
      <div
        className={classes.avatar}
        onClick={
          onClick !== undefined
            ? (e: MouseEvent<HTMLDivElement>) => onClick(e)
            : () => {}
        }
        style={style ? style : {}}
      >
        {user?.first_name ? user?.first_name[0] : ''}
      </div>
    );
  }
);

export default Avatar;
