import { ReactElement } from 'react';
import { SideBarItem } from 'interfaces/common';
import useStyles from './styles';
import { ListItemIcon, List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  checkUserPermissions,
  validateFeatureNotAvailable,
} from 'utils/userRoleUtils';
import { setOpenDrawer } from 'services/common/slice';
import { useDispatch } from 'react-redux';

interface CollapseMenuItemProps {
  value: string;
  content: string;
  itemIcon: ReactElement;
  onClick?: () => void;
  isSelected: (value: string) => boolean;
  badge?: ReactElement | null;
}

const CollapseMenuItem = ({
  value,
  content,
  itemIcon,
  onClick,
  isSelected,
  badge,
}: CollapseMenuItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation(['sidebar']);

  return (
    <ListItem
      className={`${classes.childMenuItem} ${
        isSelected(value) ? classes.menuActive : ''
      }`}
      onClick={(e: any) => {
        e.stopPropagation();
        onClick !== undefined && onClick();
      }}
      id={value}
    >
      <ListItemIcon style={{ minWidth: 'auto' }}>
        {itemIcon}
        {badge}
      </ListItemIcon>
      <ListItemText
        primary={`${t(content)}`}
        className={classes.listItemMargin}
      />
    </ListItem>
  );
};

interface CollapseListItemProps {
  listItem: Array<SideBarItem>;
  isSelected: (value: string) => boolean;
  style?: any;
}

const CollapseListItem = ({
  listItem,
  isSelected,
  style = null,
}: CollapseListItemProps) => {
  const dispatch = useDispatch();

  return (
    <div style={{ paddingLeft: 24, cursor: 'pointer', ...style }}>
      <List component="div">
        {listItem.map((item: SideBarItem) => {
          if (item.hide) return null;
          if (item.permission && !checkUserPermissions(item.permission, true))
            return null;

          return (
            <CollapseMenuItem
              value={item.value}
              key={item.value}
              content={item.translation}
              itemIcon={item.icon}
              onClick={() => {
                if (
                  item.permission &&
                  validateFeatureNotAvailable(item.permission)
                ) {
                  dispatch(setOpenDrawer(false));
                  return;
                }
                
                item.onClick !== undefined && item.onClick();
              }}
              isSelected={isSelected}
              badge={item.badge}
            />
          );
        })}
      </List>
    </div>
  );
};

export default CollapseListItem;
