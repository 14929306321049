import axiosAuthoringInstance from 'api/axios-client';

export const getWorkProcedures = (params) => {
  const newParams = new URLSearchParams();
  params.author_name?.map((el) => newParams.append('author_name', el));
  params.substance_name?.map((el) => newParams.append('substance_name', el));
  params.location_name?.map((el) => newParams.append('location_name', el));
  params.status?.map((el) => newParams.append('status', el));

  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);
  return axiosAuthoringInstance
    .get('workProcedureRevisions/', { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getMyWorkProcedures = (params) => {
  const newParams = new URLSearchParams();
  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);
  return axiosAuthoringInstance
    .get('workProcedureRevisions/linkedToUser/', { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getWorkProceduresFilter = () => {
  return axiosAuthoringInstance
    .get('workProcedures/filters/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getRiskAssessmentFilters = () => {
  let apiUrl = `riskAssessment/filters/`;
  return axiosAuthoringInstance
    .get(apiUrl)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getApprovedRiskEvaluations = (params) => {
  const reqParams = new URLSearchParams();
  reqParams.append('page', params.page);
  reqParams.append('page_size', params.page_size);
  if (params.product_name) {
    reqParams.append('product_name', params.product_name);
  }

  return axiosAuthoringInstance
    .get('/riskEvaluations/approved', { params: reqParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getRiskEvaluations = (params) => {
  const reqParams = new URLSearchParams();
  reqParams.append('page', params.page);
  reqParams.append('page_size', params.page_size);
  reqParams.append(
    'show_risk_assessments_on_archived_locations',
    params.show_risk_assessments_on_archived_locations
  );
  if (params.status && params.status.length > 0) {
    params.status.map((el) => reqParams.append('status', el));
  }
  if (params.location) {
    reqParams.append('location', params.location);
  }
  if (params.authors && params.authors.length > 0) {
    params.authors.map((el) => reqParams.append('author', el));
  }
  if (params.product_name) {
    reqParams.append('product_name', params.product_name);
  }
  return axiosAuthoringInstance
    .get(`riskEvaluations/`, { params: reqParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getPPE = (data) => {
  return axiosAuthoringInstance
    .get('ppe/', data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getPPEControls = (data) => {
  return axiosAuthoringInstance
    .get('ppe/controls/', data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getPPEEmergencyControls = (data) => {
  return axiosAuthoringInstance
    .get('ppe/emergencyControls/', data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getClassificationFrequency = () => {
  return axiosAuthoringInstance
    .get('classifications/frequency/', {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getClassificationSubstance = () => {
  return axiosAuthoringInstance
    .get('classifications/substance/', {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
