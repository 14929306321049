import { makeStyles } from '@mui/styles';
// import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    boxShadow: '10px 0px 10px rgb(0, 0, 0, 0.05)',
    zIndex: 1,
    width: 60,
    position: 'fixed',
    transition: 'width 0.25s ease-in-out 0s, box-shadow 0.25s ease-in-out 0s',
    left: 0,
    background: '#fff',
    height: 'calc(100% - 60px)',
    '& .MuiList-root .MuiBox-root': {
      transition: 'opacity 0.25s ease-in-out 0s',
      overflow: 'hidden',
      opacity: 0,
      marginLeft: 0,
    },
  },
  open: {
    width: 300,
    '& .MuiList-root .MuiBox-root': {
      // transition: 'opacity 0.25s ease-in-out 0s',
      opacity: 1,
      marginLeft: 10,
    },
  },
  openSidebarTex: {
    '& .MuiTypography-root': {
      whiteSpace: 'normal!important',
    }
  },
  openLocationSubMenu: {
    width: 300,
  },
  listItemRoot: {
    '&.Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
  listItemMargin: {
    marginLeft: '5px',
    marginTop: '0!important',
    marginBottom: '0!important',

    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
  widthCustom: {
    width: '100%',
    marginLeft: '25px',
  },
  labelArrowBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
  menuItemHasChildren: {
    paddingRight: 0,
    '& .MuiListItem-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  menuItem: {
    '&.MuiListItem-root': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '12px 10px 12px 18px',
      color: theme.palette.colors.textLight,
      '& svg': {
        minWidth: 'auto',
        fill: theme.palette.colors.textLight,
      },
      '&:hover': {
        color: theme.palette.colors.primary,
        background: theme.palette.hoverColor.main,
        '& svg': {
          fill: theme.palette.colors.primary,
        },
      },
    },
  },
  childMenuItem: {
    padding: '12px 9px 12px 8px!important',
    color: theme.palette.colors.textLight,
    '& svg': {
      minWidth: 'auto',
      fill: theme.palette.colors.textLight,
    },
    '&:hover': {
      color: theme.palette.colors.primary,
      background: theme.palette.hoverColor.main,
      '& svg': {
        fill: theme.palette.colors.primary,
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.colors.primary,
      },
    },
  },
  menuActive: {
    '&.MuiListItem-root': {
      background: theme.palette.hoverColor.main,
      color: theme.palette.colors.primary,
      '& .MuiSvgIcon-root': {
        fill: theme.palette.colors.primary,
      },
      '& svg': {
        fill: theme.palette.colors.primary,
      },
    }
  },
  sidebarIconsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    paddingTop: '1vw',
    position: 'relative',
  },
  disabled: {
    display: 'none',
  },
  enabled: {
    position: 'absolute',
    width: '100%',
    height: '50%',
    backgroundColor: 'black',
  },
  '&.MuiListItem-root &.MuiListItem-gutters': {},
  iconWrapper: {
    width: '100%',
    paddingTop: 12,
    paddingBottom: 12,

    '&:hover': {
      background: '#ECF7FF',
      cursor: 'pointer',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: 16,
      paddingRight: 'unset',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  iconsWrapperRoot: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'row',
    height: '100%',
    marginTop: 5,
  },
  iconHover: {
    paddingTop: 4,
    paddingBottom: 4,

    '&:hover': {
      backgroundColor: '#ECF7FF',
    },
    '&:hover .MuiSvgIcon-root': {},
  },
  iconWrapperMore: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '0!important',
    '& .MuiListItem-container': {
      width: '100%',
    },
  },
  sidebarIconsMoreWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    height: '100%',
    marginBottom: '20px',
    overflow: 'hidden',
  },
  logoMax: {
    width: '95%',
    display: 'flex',
    marginLeft: '13px',
    justifyContent: 'space-between',
    margin: 0,
  },
  showMoreIcon: {
    width: 10,
    cursor: 'pointer'
  },
  logoMin: {
    display: 'flex',
    cursor: 'pointer',
    margin: 0,
    marginLeft: '13px',
    justifyContent: 'space-between',
  },
  logoImg: {
    width: 'auto',
    height: 33,
    cursor: 'pointer'
  },
  logoImgMobile: {
    width: 'auto',
    height: 33,
    marginLeft: 18,
    marginBottom: 10,
    marginTop: 10
  },
  popper: {
    fontSize: '1rem',
    backgroundColor: '#FFC93C',
    color: '#000000',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    overflowX: 'unset',
    overflowY: 'unset',
    cursor: 'pointer',
    borderRadius: 4,
    zIndex: 2,
    '&::before': {
      content: '""',
      position: 'absolute',
      marginRight: '-0.71em',
      top: 12,
      left: 0,
      width: 10,
      height: 10,
      backgroundColor: '#FFC93C',
      transform: 'translate(-50%, 50%) rotate(225deg)',
      clipPath:
        'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  },
  poperContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  menuItemText: {
    flex: 1,
    marginLeft: 10,
    '& .MuiTypography-root': {
      fontSize: 14,
      flex: 1,
      whiteSpace: 'nowrap',
    },
  },
  menuItemIcon: {},
  bottomSidebar: {
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&.open': {
      width: 300,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  childrenItemListWrap: {
    transition: 'max-height 0.25s ease-in-out',
    maxHeight: 0
  },
  menuDivision: {
    padding: '30px 0 15px',
    height: 1,
    width: '100%',
    borderBottom: '1px solid rgba(58, 53, 65, 0.12)',
  },
  vertical: {
    right: 6,
    top: 0,
    width: 11,
    '& .simplebar-scrollbar:before': {
      top: 2,
      bottom: 2
    }
  },
  isExpand: {
    width: '100%',
    maxHeight: 450,
  },
  iconWithBackground: {
    width: 14.67, 
    height: 14.67, 
    padding: 1, 
    fill: '#FFF!important', 
    backgroundColor: '#626DF9', 
    borderRadius: '50%',
    marginRight: 9
  }
}));

export default useStyles;
