import axios from 'axios';
import { BASE_API_URL, EXCLUDE_REDIRECT_LOGIN_ERROR_CODES, TOO_MANY_SDS_ERROR_CODES } from 'api/constants';
import { renderSnackbar } from 'utils';
import { clearUserSessionFromStorage, setUserSessionToStorage } from 'utils/helper';
// import { reportError } from 'api/auth/post';
import { isTokenAvailable } from 'utils/validateToken';
import i18next from 'utils/i18n';
import store from 'store/store';
import { setOpenUpgradePlanPopup } from 'services/common/slice';

let expiredTokenDialog = false;
let isRefreshing = false;
let waitingQueue: any[] = [];
const accessToken = 
  sessionStorage.getItem('qr_login_token') 
  ? sessionStorage.getItem('qr_login_token') 
  : localStorage.getItem('access_token');

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 50000,
  headers: {
    Authorization: accessToken
      ? 'JWT ' + accessToken
      : null,
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

const processQueue = (err: any) => {
  waitingQueue.forEach(item => {
    if (err) {
      item.reject(err);
    } else {
      item.resolve();
    }
  })
}

export const handleRedirectLogin = () => {
  const pathName = window.location.pathname;
  const redirectUrl = pathName !== '/' ? `/login/?redirectPath=${pathName}` : '/login/';
  clearUserSessionFromStorage();
  window.location.href = redirectUrl;
}

export const setAxiosTimeout = (timeout: number): void => {
  axiosInstance.defaults.timeout = timeout;
};

const notifySnackbar = (error: any) => {
  if (typeof error.response?.data?.error_message == 'string') {
    renderSnackbar([error.response.data.error_message]);
  }
  if (typeof error.response?.data?.error_message == 'object') {
    const res: string[] = [];
    Object.entries(error.response.data.error_message).map(
      ([key, value]: any) => {
        res.push(
          `<span style="text-transform: capitalize">${key}</span>: ${value.join(
            ', '
          )}`
        );
      }
    );
    renderSnackbar(res);
  }
  return Promise.reject(error);
}
  
axiosInstance.interceptors.request.use(
  (config: any) => {
    let accessToken = localStorage.getItem('access_token');
    if (sessionStorage.getItem('qr_login_token') ) {
      accessToken = sessionStorage.getItem('qr_login_token');
      if (!config.url.includes('login_method=qrcode')) {
        const separator = config.url.includes('?') ? '&' : '?';
        config.url = `${config.url}${separator}login_method=qrcode`;
      }
    }
    const login_method = localStorage.getItem('login_method');
    if (login_method == 'qrcode') {
      if (!config.url.includes('login_method=qrcode')) {
        const separator = config.url.includes('?') ? '&' : '?';
        config.url = `${config.url}${separator}login_method=qrcode`;
      }
    }
    config.headers['Authorization'] = accessToken ? `JWT ${accessToken}` : null;
        return config;
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle request time out
    if (!error.response)  {
      renderSnackbar([i18next.t('common:request_time_out')]);
      return Promise.reject({response: {status: 408}});
    }

    const originalRequest = error.config;

    // Prevent infinite loops
    if (originalRequest.url.includes('/auth/refresh-token')) {
      handleRedirectLogin();
      return Promise.reject(error);
    }

    // Handle refresh token
    if (error.response.status === 401) {
      if (sessionStorage.getItem('qr_login_token')) {
        sessionStorage.removeItem('qr_login_token');
        window.location.href='/';
      }
      const accessToken = localStorage.getItem('access_token') ?? '';
      const authorizationHeader = accessToken ? 'JWT ' + accessToken : null;
      // Reload api when refresh token has just finished
      if (authorizationHeader !== originalRequest.headers['Authorization']) {
        return axiosInstance(originalRequest);
      }

      // Waiting queue push if refresh-token api is pending
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          waitingQueue.push({ resolve, reject });
        }).then(() => {
          return axiosInstance(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        })
      }

      const refreshToken: string | null = localStorage.getItem('refresh_token');
      if (refreshToken && isTokenAvailable(refreshToken)) { // Refresh token existed and in JWT format
        isRefreshing = true;
        return axiosInstance
          .post('/auth/refresh-token/', { token: refreshToken })
          .then((response) => {
            setUserSessionToStorage(undefined, refreshToken, response.data.token);
            isRefreshing = false;
            processQueue(null);
            waitingQueue = [];
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            processQueue(err);
            return Promise.reject(error);
          });
      } else {
        if (!expiredTokenDialog) {
          expiredTokenDialog = true;
          if (!EXCLUDE_REDIRECT_LOGIN_ERROR_CODES.includes(error.response?.data.error_code)){
            handleRedirectLogin()
          }
        }
      }
      return Promise.reject(error);
    }

    /* Turn off report error through email */
    if (error.response.status === 500) {
      renderSnackbar(['Server currently under maintenance, please try again later']);
    }
    
    if (TOO_MANY_SDS_ERROR_CODES.includes(error.response?.data.error_code)){
      store.dispatch(setOpenUpgradePlanPopup(true));
      return Promise.reject(error);
    }
    
    // Handle snackbar notification
    if (
      error.response?.data?.error_code !== undefined &&
      !originalRequest.url.includes('binder')
    ) return notifySnackbar(error);

    return Promise.reject(error);
  }
);

export default axiosInstance;