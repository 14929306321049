import UserInterface from '../../interfaces/UserInterface';
import { add, format, isAfter } from 'date-fns'
import { openMessage, popupMessageText } from '../../store/pageAction/pageAction';
import { checkUserExpire, clearUserSessionFromStorage } from '../../utils/helper';
import { renderSnackbar } from '../../utils';
import { Dispatch } from 'redux';
import { isOwner } from '../../utils/userRoleUtils';
import i18next from 'utils/i18n';

const ValidateSubscriptionPlan = (userData: UserInterface, dispatch: Dispatch<any>) => {
  const subscription_plan_expired_warning = localStorage.getItem('subscription_plan_expired_warning');
  const expiredWarning = !subscription_plan_expired_warning
    && userData?.is_owner
    && userData?.customer?.inventory_subscription_plan
    && !userData?.customer?.inventory_subscription_plan?.expired
    && userData?.customer?.inventory_subscription_plan?.available_to

  // if (userData?.customer?.inventory_subscription_plan?.expired) {
  //   renderSnackbar(["Your subscription plan has expired"])
  // }

  if (expiredWarning) {
    const next15Days = format(add(new Date(), { days: 15 }), 'yyyy-MM-dd');
    if (isAfter(
      new Date(next15Days), 
      new Date(
        userData.customer.inventory_subscription_plan.available_to 
        ?? ''
      )
    )) {
      dispatch(popupMessageText(i18next.t('common:subscription_is_about_expire')));
      dispatch(openMessage(true));
      localStorage.setItem("subscription_plan_expired_warning", '1');
    }
  }

  if (checkUserExpire(userData)) {
    renderSnackbar([i18next.t('common:subscription_has_expired')]);

    if (userData.qr_login_token) {
      window.location.href = "/service-unavailable";
      return;
    }
    else if (!isOwner(userData)) {
      clearUserSessionFromStorage();
      window.location.href = "/login";
      return;
    }

    const location = window.location.pathname;

    if (!location.includes("/administration/subscription")) {
      window.location.href = "/administration/subscription";
    }
  }
}

export default ValidateSubscriptionPlan;