import { RegionItem } from 'interfaces/common';

const Regions: Array<RegionItem> = [
  { name: 'EU', value: 'EU' },
  { name: 'US', value: 'US' },
  { name: 'CA', value: 'CA' },
  { name: 'NZ', value: 'NZ' },
  { name: 'AU', value: 'AU' },
  { name: 'MY', value: 'MY' },
  { name: 'CN', value: 'CN' },
  { name: 'MX', value: 'MX' },
  { name: 'ZA', value: 'ZA' },
];

export default Regions;
