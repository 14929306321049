export enum PERMISSION {
  /* Access */
  ACCESS_TO_MY_LOCATIONS_PAGE = 'access_to_my_locations_page',
  ACCESS_LOCATION_MANAGEMENT_PAGE = 'access_location_management_page',
  ACCESS_TO_ALL_OUR_SDS_PAGE = 'access_to_all_our_sds_page',
  ACCESS_TO_ARCHIVE_PAGE = 'access_to_archive_page',
  ACCESS_TO_NEWER_VERSION_DATA = 'access_to_newer_version_data',
  ACCESS_SUBSTANCE_WITH_MISSING_SDS_PAGE = 'access_substance_with_missing_sds_page',
  ACCESS_HAZARDOUS_SUBSTANCES_PAGE = 'access_hazardous_substances_page',
  ACCESS_TO_USER_MANAGEMENT_PAGE = 'access_to_user_management_page',
  ACCESS_TO_IMPORT_STATUS_PAGE = 'access_to_import_status_page',
  ACCESS_TO_QR_CODE_MANAGEMENT_PAGE = 'access_to_qr_code_management_page',
  ACCESS_TO_ORGANIZATION_PAGE = 'access_to_organization_page',
  ACCESS_TO_SUBSCRIPTION_PAGE = 'access_to_subscription_page',
  ACCESS_TO_GO_LIVE_CHECKLIST_PAGE = 'access_to_go_live_checklist_page',
  ACCESS_TO_SETTING_FUNCTION_PAGE = 'access_to_setting_function_page',
  ACCESS_TO_MY_WORK_PROCEDURE_PAGE = 'access_to_my_work_procedure_page',
  ACCESS_TO_MY_EXPOSURE_PAGE = 'access_to_my_exposure_page',
  ACCESS_TO_USE_DISPOSAL_PAGE = 'access_to_use_disposal_page',
  /* User */
  CREATE_USERS = 'create_users',
  SET_ROLES_ON_USERS = 'set_roles_on_users',
  PRINT_QR_CODE = 'print_qr_code',
  ENABLE_TWO_FACTOR_AUTHENTICATION = 'enable_two_factor_authentication',
  /* Location */
  ADD_LOCATIONS = 'add_locations',
  CAN_SEE_FILTER_CHECKBOXES_ON_LOCATION = 'can_see_filter_checkboxes_on_location',
  ARCHIVE_LOCATION = 'archive_location',
  EDIT_LOCATION = 'edit_location',
  SET_ROLES_ON_LOCATION = 'set_roles_on_location',
  /* SDS */
  SEE_GHS_LABELS = 'see_ghs_labels',
  PRINT_SECONDARY_CONTAINER_LABELING = 'print_secondary_container_labeling',
  IMPORT_SDS_BINDERS = 'import_sds_binders',
  PRINT_SDS_BINDER = 'print_sds_binder',
  STORE_AMOUNT_ON_SUBSTANCE = 'store_amount_on_substance',
  CHANGE_AMOUNT_MEASURE = 'change_amount_measure',
  CHANGE_STANDARD_PACKING_SIZE = 'change_standard_packing_size',
  EXPORT_TO_XLS_SHEET = 'export_to_xls_sheet',
  UPGRADE_SDS_TO_NEWER = 'upgrade_sds_to_newer',
  ALERT_ON_HAZARDOUS_SUBSTANCES = 'alert_on_hazardous_substances',
  ADD_SDS_FROM_GLOBAL_DATABASE = 'add_sds_from_global_database',
  ADD_SDS_FROM_IN_USE_BY_CUSTOMER = 'add_sds_from_in_use_by_customer',
  ADMIN_WITH_APPROVAL = 'admin_with_approval',
  IMPORT_PRODUCT_LIST = 'import_product_list',
  HELP_SETUP_LIBRARY = 'help_setup_library',
  MOVE_SDS = 'move_sds',
  REPLACE_SDS = 'replace_sds',
  ADD_ATTACHMENT = 'add_attachment',
  SEE_SAFETY_SUMMARY = 'see_safety_summary',
  EDIT_SDS = 'edit_sds',
  ALLOWED_TO_ARCHIVE_SDS = 'allowed_to_archive_SDS',
  ALLOW_TO_HIDE_FOR_NON_ADMIN = 'allow_to_hide_for_non_admin',
  ADD_SUBSTANCE = 'add_substance',
  ALLOWED_TO_SHOOT_PICTURE = 'allowed_to_shoot_picture',
  LOG_PRESENCE = 'log_presence',
  /* Substitution */
  SUBSTITUTION = 'substitution',
  /* Risk assessment */
  RISK_ASSESSMENT = 'risk_assessment',
  WORK_PROCEDURE = 'work_procedure',
  /* Exposure */
  EXPOSURE = 'exposure',
  /* Reports */
  REPORTS = 'reports',
  /* Other */
  ALLOWED_TO_DO_GLOBAL_SEARCH = 'allowed_to_do_global_search',
  SEE_CUSTOM_FIELDS = 'see_custom_fields',
  SEE_FEATURE_NOT_AVAILABLE_POPUP = 'see_feature_not_available_popup'
}

export type LOCATION_PERMISSION = Extract<
  PERMISSION,
  /* Location */
  | PERMISSION.ARCHIVE_LOCATION
  | PERMISSION.EDIT_LOCATION
  | PERMISSION.SET_ROLES_ON_LOCATION
  /* SDS */
  | PERMISSION.PRINT_SDS_BINDER
  | PERMISSION.STORE_AMOUNT_ON_SUBSTANCE
  | PERMISSION.CHANGE_AMOUNT_MEASURE
  | PERMISSION.CHANGE_STANDARD_PACKING_SIZE
  | PERMISSION.EXPORT_TO_XLS_SHEET
  | PERMISSION.UPGRADE_SDS_TO_NEWER
  | PERMISSION.ADMIN_WITH_APPROVAL
  | PERMISSION.MOVE_SDS
  | PERMISSION.REPLACE_SDS
  | PERMISSION.ADD_ATTACHMENT
  | PERMISSION.SEE_SAFETY_SUMMARY
  | PERMISSION.EDIT_SDS
  | PERMISSION.ALLOWED_TO_ARCHIVE_SDS
  | PERMISSION.ALLOW_TO_HIDE_FOR_NON_ADMIN
  | PERMISSION.ADD_SUBSTANCE
  | PERMISSION.LOG_PRESENCE
>;

export type USER_PERMISSION = Extract<
  PERMISSION,
  /* Access */
  | PERMISSION.ACCESS_TO_MY_LOCATIONS_PAGE
  | PERMISSION.ACCESS_LOCATION_MANAGEMENT_PAGE
  | PERMISSION.ACCESS_TO_ALL_OUR_SDS_PAGE
  | PERMISSION.ACCESS_TO_ARCHIVE_PAGE
  | PERMISSION.ACCESS_TO_NEWER_VERSION_DATA
  | PERMISSION.ACCESS_SUBSTANCE_WITH_MISSING_SDS_PAGE
  | PERMISSION.ACCESS_HAZARDOUS_SUBSTANCES_PAGE
  | PERMISSION.ACCESS_TO_USER_MANAGEMENT_PAGE
  | PERMISSION.ACCESS_TO_IMPORT_STATUS_PAGE
  | PERMISSION.ACCESS_TO_QR_CODE_MANAGEMENT_PAGE
  | PERMISSION.ACCESS_TO_ORGANIZATION_PAGE
  | PERMISSION.ACCESS_TO_SUBSCRIPTION_PAGE
  | PERMISSION.ACCESS_TO_GO_LIVE_CHECKLIST_PAGE
  | PERMISSION.ACCESS_TO_SETTING_FUNCTION_PAGE
  | PERMISSION.ACCESS_TO_MY_WORK_PROCEDURE_PAGE
  | PERMISSION.ACCESS_TO_MY_EXPOSURE_PAGE
  | PERMISSION.ACCESS_TO_USE_DISPOSAL_PAGE
  /* User */
  | PERMISSION.CREATE_USERS
  | PERMISSION.SET_ROLES_ON_USERS
  | PERMISSION.PRINT_QR_CODE
  /* Location */
  | PERMISSION.ADD_LOCATIONS
  | PERMISSION.CAN_SEE_FILTER_CHECKBOXES_ON_LOCATION
  /* SDS */
  | PERMISSION.SEE_GHS_LABELS
  | PERMISSION.PRINT_SECONDARY_CONTAINER_LABELING
  | PERMISSION.IMPORT_SDS_BINDERS
  | PERMISSION.ALERT_ON_HAZARDOUS_SUBSTANCES
  | PERMISSION.ADD_SDS_FROM_GLOBAL_DATABASE
  | PERMISSION.ADD_SDS_FROM_IN_USE_BY_CUSTOMER
  | PERMISSION.IMPORT_PRODUCT_LIST
  | PERMISSION.HELP_SETUP_LIBRARY
  | PERMISSION.ALLOWED_TO_SHOOT_PICTURE
  /* Substitution */
  | PERMISSION.SUBSTITUTION
  /* Risk assessment */
  | PERMISSION.RISK_ASSESSMENT
  | PERMISSION.WORK_PROCEDURE
  /* Exposure */
  | PERMISSION.EXPOSURE
  /* Reports */
  | PERMISSION.REPORTS
  /* Other */
  | PERMISSION.ALLOWED_TO_DO_GLOBAL_SEARCH
  | PERMISSION.SEE_CUSTOM_FIELDS
  | PERMISSION.SEE_FEATURE_NOT_AVAILABLE_POPUP
  | PERMISSION.ENABLE_TWO_FACTOR_AUTHENTICATION
>;
