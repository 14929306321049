import { createAsyncThunk } from "@reduxjs/toolkit";
import { getInventoryStats } from 'api';
import { ErrorPayload } from "interfaces/common/ApiResponseInterface";
import { GetStatisticsResponseDto } from "interfaces/home";

export const fetchStatistics = createAsyncThunk<
  GetStatisticsResponseDto,
  {},
  {
    rejectValue: ErrorPayload
  }
>(
  'home/fetchStatistics',
  async(_, { rejectWithValue }) => {
    const response = await getInventoryStats();
    if (response.status !== 200 || !response.data || response.data.status_code) 
      return rejectWithValue(response.data as ErrorPayload);
    return response.data as GetStatisticsResponseDto;
  }
)
