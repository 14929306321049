let initialState = {
  showLocationSubMenu: false,
  findData: null,
  locationSelected: false,
  loading: false,
  showLocationSettings: false,
  newerCount: 0,
  openAlert: false,
  setLocationSidebarDrawer: false,
  setReport: false,
  setExpandTree: false,
  organizationFormIsNotSaved: false,
  popupMessageText: '',
  workProcedureSubstanceDetails: {},
  workProcedureSubstancePPEItems: {},
  disableWorkProcedureEdit: false,
  selectWorkProcedurePopupType: 'details',
  openEditRiskSDSAmount: false,
  scrollToHazardBlock: null,
  authenticating: false,
  changesNeedToBeSaved: false,
  setOpenImportSdsUrlPopup: false,
  showHeader: true,
  selectedBinderID: null,
  disableRiskAssessmentEdit: false,
  selectedExposure: null,
};

const pageReducer = (state = initialState, action) => {
  const actionType = action.type;
  switch (actionType) {
    case 'selectedExposure':
      return {
        ...state,
        selectedExposure: action.payload,
      };
    case 'changeLocationSubMenuStatus':
      return {
        ...state,
        showLocationSubMenu: action.boolean,
      };
    case 'disableWorkProcedureEdit':
      return {
        ...state,
        disableWorkProcedureEdit: action.boolean,
      };
    case 'scrollToHazardBlock':
      return {
        ...state,
        scrollToHazardBlock: action.payload,
      };
    case 'workProcedureSubstancePPEItems': {
      return {
        ...state,
        workProcedureSubstancePPEItems: action.payload,
      };
    }
    case 'openAlert':
      return {
        ...state,
        openAlert: action.payload,
      };
    case 'openMessage':
      return {
        ...state,
        openMessage: action.payload,
      };
    case 'popupMessageText':
      return {
        ...state,
        popupMessageText: action.payload,
      };
    case 'popupMessageOption':
      return {
        ...state,
        popupMessageOption: action.payload,
      };
    case 'setExpandTree':
      return {
        ...state,
        setExpandTree: action.payload,
      };
    case 'setLocationSidebarDrawer':
      return {
        ...state,
        setLocationSidebarDrawer: action.payload,
      };
    case 'setOpenImportSdsUrlPopup':
      return {
        ...state,
        setOpenImportSdsUrlPopup: action.payload,
      };
    case 'setReport':
      return {
        ...state,
        setReport: action.payload,
      };
    case 'newerCount':
      return {
        ...state,
        newerCount: action.payload,
      };
    case 'selectedIndex':
      return {
        ...state,
        selectedId: action.payload,
      };
    case 'showLocationSettings':
      return {
        ...state,
        showLocationSettings: action.payload,
      };
    case 'locationSelected':
      return {
        ...state,
        locationSelected: action.payload,
      };
    case 'changesNeedToBeSaved':
      return {
        ...state,
        changesNeedToBeSaved: action.boolean,
      };
    case 'findedData':
      return {
        ...state,
        findData: [action.payload],
      };
    case 'loading':
      return {
        ...state,
        loading: action.payload,
      };
    case 'reloadAllOurSds':
      return {
        ...state,
        reloadAllOurSds: action.payload,
      };
    case 'sdsInfoActive':
      return {
        ...state,
        sdsInfoActive: action.payload,
      };
    case 'organizationFormIsNotSaved':
      return {
        ...state,
        sdsInfoActive: action.payload,
      };
    case 'workProcedureSubstanceDetails':
      return {
        ...state,
        workProcedureSubstanceDetails: action.payload,
      };
    case 'selectWorkProcedurePopupType':
      return {
        ...state,
        selectWorkProcedurePopupType: action.payload,
      };
    case 'openEditRiskSDSAmount':
      return {
        ...state,
        openEditRiskSDSAmount: action.boolean,
      };
    case 'updateAuthenticating':
      return {
        ...state,
        authenticating: action.boolean,
      };
    case 'showHeader':
      return {
        ...state,
        showHeader: action.boolean,
      };
    case 'selectedBinderID':
      return {
        ...state,
        selectedBinderID: action.payload,
      };
    case 'logout':
      return initialState;
    case 'disableRiskAssessmentEdit':
      return {
        ...state,
        disableRiskAssessmentEdit: action.boolean,
      };
    default:
      return state;
  }
};

export default pageReducer;
