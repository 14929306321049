const availableCountries = [
  {
    id: 1,
    name: 'Antarctica',
    short_name: 'aq',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 2,
    name: 'Austria',
    short_name: 'at',
    emergency_agency_name: 'Vergiftungsinformationszentrale Österreich',
    emergency_agency_phone_number: '+4314064343',
  },
  {
    id: 3,
    name: 'Australia',
    short_name: 'au',
    emergency_agency_name: 'Poisons Information Centre',
    emergency_agency_phone_number: '131126',
  },
  {
    id: 4,
    name: 'Bosnia and Herzegovina',
    short_name: 'ba',
    emergency_agency_name: null,
    emergency_agency_phone_number: null,
  },
  {
    id: 5,
    name: 'Belgium',
    short_name: 'be',
    emergency_agency_name: 'Antigifcentrum',
    emergency_agency_phone_number: '+3270245245',
  },
  {
    id: 6,
    name: 'Bulgaria',
    short_name: 'bg',
    emergency_agency_name: 'Национален токсикологичен информационен център',
    emergency_agency_phone_number: '+35929154409',
  },
  {
    id: 7,
    name: 'Brazil',
    short_name: 'br',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 8,
    name: 'Belarus',
    short_name: 'by',
    emergency_agency_name: null,
    emergency_agency_phone_number: null,
  },
  {
    id: 9,
    name: 'Catalonia',
    short_name: 'ca',
    emergency_agency_name: null,
    emergency_agency_phone_number: null,
  },
  {
    id: 10,
    name: 'Switzerland',
    short_name: 'ch',
    emergency_agency_name:
      'Schweizerisches Toxikologisches Informationszentrum',
    emergency_agency_phone_number: '+41442515151',
  },
  {
    id: 11,
    name: 'China',
    short_name: 'cn',
    emergency_agency_name: null,
    emergency_agency_phone_number: null,
  },
  {
    id: 12,
    name: 'Cyprus',
    short_name: 'cy',
    emergency_agency_name: null,
    emergency_agency_phone_number: null,
  },
  {
    id: 13,
    name: 'Czech Republic',
    short_name: 'cz',
    emergency_agency_name: 'Toxikologické informační středisko',
    emergency_agency_phone_number: '+420 224919293',
  },
  {
    id: 14,
    name: 'Germany',
    short_name: 'de',
    emergency_agency_name: 'Giftnotrufe',
    emergency_agency_phone_number:
      '03019240 022819240 0361730730 076119240 055119240 0613119240 08919240',
  },
  {
    id: 15,
    name: 'Denmark',
    short_name: 'dk',
    emergency_agency_name: 'Nationaal Vergiftigingen Informatie Centrum (NVIC)',
    emergency_agency_phone_number: '+310302748888',
  },
  {
    id: 16,
    name: 'Estonia',
    short_name: 'ee',
    emergency_agency_name: 'Nationaal Vergiftigingen Informatie Centrum (NVIC)',
    emergency_agency_phone_number: '+3726269390',
  },
  {
    id: 17,
    name: 'Spain',
    short_name: 'es',
    emergency_agency_name: 'Servicio de Información Toxicológica',
    emergency_agency_phone_number: '+34915620420',
  },
  {
    id: 18,
    name: 'European Union',
    short_name: 'eu',
    emergency_agency_name: null,
    emergency_agency_phone_number: null,
  },
  {
    id: 19,
    name: 'Finland',
    short_name: 'fi',
    emergency_agency_name: 'Myrkytystietokeskus',
    emergency_agency_phone_number: '09471977',
  },
  {
    id: 20,
    name: 'France',
    short_name: 'fr',
    emergency_agency_name: 'ORFILA (INRS)',
    emergency_agency_phone_number: '+330145425959',
  },
  {
    id: 21,
    name: 'Galicia',
    short_name: 'ga',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 22,
    name: 'Georgia',
    short_name: 'ge',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 23,
    name: 'Greenland',
    short_name: 'gl',
    emergency_agency_name: 'Nationaal Vergiftigingen Informatie Centrum (NVIC)',
    emergency_agency_phone_number: '+310302748888',
  },
  {
    id: 24,
    name: 'Greece',
    short_name: 'gr',
    emergency_agency_name: 'Κέντρο Δηλητηριάσεων',
    emergency_agency_phone_number: '+302107793777',
  },
  {
    id: 25,
    name: 'Croatia',
    short_name: 'hr',
    emergency_agency_name: 'Centar za kontrolu otrovanja',
    emergency_agency_phone_number: '+38512348342',
  },
  {
    id: 26,
    name: 'Hungary',
    short_name: 'hu',
    emergency_agency_name: 'Egészségügyi Toxikológiai Tájékoztató Szolgálat',
    emergency_agency_phone_number: '+3614766464',
  },
  {
    id: 27,
    name: 'Indonesia',
    short_name: 'id',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 28,
    name: 'Ireland',
    short_name: 'ie',
    emergency_agency_name: 'Poisons Information Centre of Ireland',
    emergency_agency_phone_number: '+35318092566',
  },
  {
    id: 29,
    name: 'Israel',
    short_name: 'il',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 30,
    name: 'India',
    short_name: 'in',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 31,
    name: 'Iran',
    short_name: 'ir',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 32,
    name: 'Iceland',
    short_name: 'is',
    emergency_agency_name: 'Iceland Poison Information Centre',
    emergency_agency_phone_number: '+3545432222',
  },
  {
    id: 33,
    name: 'Italy',
    short_name: 'it',
    emergency_agency_name: 'Centro Antiveleni',
    emergency_agency_phone_number: '+390266101029',
  },
  {
    id: 34,
    name: 'Japan',
    short_name: 'jp',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 35,
    name: 'Kyrgyzstan',
    short_name: 'kg',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 36,
    name: 'South Korea',
    short_name: 'kr',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 37,
    name: 'Kazakhstan',
    short_name: 'kz',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 38,
    name: 'Lebanon',
    short_name: 'lb',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 39,
    name: 'Liechtenstein',
    short_name: 'li',
    emergency_agency_name: null,
    emergency_agency_phone_number: null,
  },
  {
    id: 40,
    name: 'Sri Lanka',
    short_name: 'lk',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 41,
    name: 'Lithuania',
    short_name: 'lt',
    emergency_agency_name: 'Apsinuodijimų informacijos biuro',
    emergency_agency_phone_number: '+37052362052',
  },
  {
    id: 42,
    name: 'Luxembourg',
    short_name: 'lu',
    emergency_agency_name: 'Belgisch Antigifcentrum',
    emergency_agency_phone_number: '+35280025500',
  },
  {
    id: 43,
    name: 'Latvia',
    short_name: 'lv',
    emergency_agency_name: 'Saindēšanās informācijas centra',
    emergency_agency_phone_number: '+37167042473',
  },
  {
    id: 44,
    name: 'Monaco',
    short_name: 'mc',
    emergency_agency_name: 'ORFILA (INRS)',
    emergency_agency_phone_number: '+33045425959',
  },
  {
    id: 45,
    name: 'Moldova',
    short_name: 'md',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 46,
    name: 'Montenegro',
    short_name: 'me',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 47,
    name: 'North Macedonia',
    short_name: 'mk',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 48,
    name: 'Mongolia',
    short_name: 'mn',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 49,
    name: 'Malta',
    short_name: 'mt',
    emergency_agency_name: 'Medicines & Poisons Info Office',
    emergency_agency_phone_number: '+35625456504',
  },
  {
    id: 50,
    name: 'Mexico',
    short_name: 'mx',
    emergency_agency_name: null,
    emergency_agency_phone_number: null,
  },
  {
    id: 51,
    name: 'Malaysia',
    short_name: 'my',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 52,
    name: 'Nigeria',
    short_name: 'ng',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 53,
    name: 'Netherlands',
    short_name: 'nl',
    emergency_agency_name: 'Nationaal Vergiftigingen Informatie Centrum (NVIC)',
    emergency_agency_phone_number: '+31(0)302748888',
  },
  {
    id: 54,
    name: 'Norway',
    short_name: 'no',
    emergency_agency_name: 'Giftinformasjonssentralen',
    emergency_agency_phone_number: '22 59 13 00',
  },
  {
    id: 55,
    name: 'New Zealand',
    short_name: 'nz',
    emergency_agency_name:
      'National Poisons & Hazardous Chemical Information Centre',
    emergency_agency_phone_number: '0800764766',
  },
  {
    id: 56,
    name: 'Philippines',
    short_name: 'ph',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 57,
    name: 'Pakistan',
    short_name: 'pk',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 58,
    name: 'Poland',
    short_name: 'pl',
    emergency_agency_name: 'Biuro Informacji Toksykologicznej w Warszawie',
    emergency_agency_phone_number: '+48226196654',
  },
  {
    id: 59,
    name: 'Portugal',
    short_name: 'pt',
    emergency_agency_name: 'CIAV (Centro de Informação Antivenenos)',
    emergency_agency_phone_number: '+351800250250',
  },
  {
    id: 60,
    name: 'Romania',
    short_name: 'ro',
    emergency_agency_name: 'Institutul naţional de Sănătate Publică Bucureşti',
    emergency_agency_phone_number: '+40213183606',
  },
  {
    id: 61,
    name: 'Serbia',
    short_name: 'rs',
    emergency_agency_name: null,
    emergency_agency_phone_number: null,
  },
  {
    id: 62,
    name: 'Russia',
    short_name: 'ru',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 63,
    name: 'Saudi Arabia',
    short_name: 'sa',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 64,
    name: 'Sweden',
    short_name: 'se',
    emergency_agency_name: 'Giftinformationscentralen',
    emergency_agency_phone_number: '+46104566700',
  },
  {
    id: 65,
    name: 'Singapore',
    short_name: 'sg',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 66,
    name: 'Slovenia',
    short_name: 'si',
    emergency_agency_name: 'National Emergency Poison Control',
    emergency_agency_phone_number: '+38641635500',
  },
  {
    id: 67,
    name: 'Slovakia',
    short_name: 'sk',
    emergency_agency_name: 'Národné toxikologické informačné centrum',
    emergency_agency_phone_number: '+421254774166',
  },
  {
    id: 68,
    name: 'San Marino',
    short_name: 'sm',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 69,
    name: 'Syria',
    short_name: 'sy',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 70,
    name: 'Thailand',
    short_name: 'th',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 71,
    name: 'Turkmenistan',
    short_name: 'tm',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 72,
    name: 'Tunisia',
    short_name: 'tn',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 73,
    name: 'Turkey',
    short_name: 'tr',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 74,
    name: 'Taiwan',
    short_name: 'tw',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 75,
    name: 'Ukraine',
    short_name: 'ua',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 76,
    name: 'United Kingdom',
    short_name: 'gb',
    emergency_agency_name: 'National Poisons Information Service',
    emergency_agency_phone_number: '+44 (0) 845 46 47',
  },
  {
    id: 77,
    name: 'United States',
    short_name: 'us',
    emergency_agency_name: 'CHEMTREC',
    emergency_agency_phone_number: '1-800-424-9300',
  },
  {
    id: 78,
    name: 'Uzbekistan',
    short_name: 'uz',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 79,
    name: 'Vatican City',
    short_name: 'va',
    emergency_agency_name: null,
    emergency_agency_phone_number: '',
  },
  {
    id: 80,
    name: 'Vietnam',
    short_name: 'vn',
    emergency_agency_name: null,
    emergency_agency_phone_number: null,
  },
  {
    id: 81,
    name: 'South Africa',
    short_name: 'za',
    emergency_agency_name: null,
    emergency_agency_phone_number: null,
  },
  {
    id: 82,
    name: 'Canada',
    short_name: 'ca',
    emergency_agency_name: 'CANUTEC',
    emergency_agency_phone_number: '+16139966666',
  },
];

export default availableCountries;
