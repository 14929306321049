import { PERMISSION, USER_PERMISSION } from 'enums/permissions.enums';
import { ComponentType, lazy } from 'react';
import { deferImport } from 'utils/helper';
import MyExposures from '../pages/exposures/MyExposures';

/* For route that need to be used on multiple path without re-render */
/* We will need to create an unique instance of that component here */
/* This is for react-router-dom to be able to regconize */
const Locations = lazy((): Promise<any> => {
  return deferImport(import('pages/location-management/locations'));
});
const LocationManagement = lazy((): Promise<any> => {
  return deferImport(
    import(
      'pages/location-management/chemical-on-location-v2/ChemicalOnLocation'
    )
  );
});
const SubstanceOnLocation = lazy((): Promise<any> => {
  return deferImport(
    import(
      'pages/location-management/chemical-on-location-v2/ChemicalOnLocation'
    )
  );
});
const MySds = lazy((): Promise<any> => {
  return deferImport(import('pages/my-sds/MySds'));
});
const AllOurSds = lazy(
  (): Promise<any> => deferImport(import('pages/substances/AllOurSDS'))
);
const SdsWithNewerVersion = lazy((): Promise<any> => {
  return deferImport(import('pages/substances/NewerVersion'));
});
const ArchivePage = lazy((): Promise<any> => {
  return deferImport(import('pages/archive'));
});
const HazardousSubstance = lazy((): Promise<any> => {
  return deferImport(import('pages/substances/HazardousSubstance'));
});
const GlobalSdsSearch = lazy((): Promise<any> => {
  return deferImport(import('pages/substances/global-sds-search'));
});
const WorkProcedureDetail = lazy((): Promise<any> => {
  return deferImport(import('pages/work-procedures/work-procedure-details-v2'));
});
const RiskEvaluation = lazy((): Promise<any> => {
  return deferImport(import('pages/risk-assessments/risk-evaluation'));
});
const Substitutions = lazy((): Promise<any> => {
  return deferImport(import('pages/substitution/Substitutions'));
});
const CreateSubstitution = lazy((): Promise<any> => {
  return deferImport(
    import('pages/substitution/create-substitution/CreateSubstitution')
  );
});
const CreateExposure = lazy((): Promise<any> => {
  return deferImport(import('pages/exposures/create-exposure/CreateExposure'));
});
const Exposures = lazy((): Promise<any> => {
  return deferImport(import('pages/exposures/Exposures'));
});
const ExposureDetails = lazy((): Promise<any> => {
  return deferImport(
    import('pages/exposures/exposure-details/ExposureDetails')
  );
});

export interface RouteInterface {
  path: string;
  key?: string;
  exact: boolean;
  active: boolean;
  RouteComponent: ComponentType<any>;
  props?: { [name: string]: any };
  permission?: USER_PERMISSION;
  customerPermission?: PERMISSION;
  pageTitle?: string;
  needFetchUser?: boolean;
}

export const guardRoutes: Array<RouteInterface> = [
  {
    path: '/v2',
    exact: true,
    RouteComponent: lazy(() => import('pages/home/HomeV2')),
    active: true,
  },
  {
    path: '/',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/home/Home'));
    }),
    active: true,
  },
  {
    path: '/open_info_email',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/open-email-page/OpenEmailPage'));
    }),
    active: true,
  },
  {
    path: '/open_qr_poster',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/open-qr-login-page/OpenQRLoginPage'));
    }),
    active: true,
  },
  /* Add product by picture stand alone page */
  {
    path: '/add_product_by_picture',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/add_product_by_picture'));
    }),
    active: true,
  },
  /* Location management */
  {
    path: '/location-management',
    key: 'location-management',
    exact: true,
    RouteComponent: LocationManagement,
    active: true,
  },
  {
    path: '/location-management/sds-detail/:pdfId?',
    key: 'location-management',
    exact: true,
    RouteComponent: LocationManagement,
    active: true,
  },
  {
    path: '/location-management/substance-detail/:substanceId?',
    key: 'location-management',
    exact: true,
    RouteComponent: LocationManagement,
    active: true,
  },
  {
    path: '/location-management/location-detail/:locationId',
    key: 'location-management',
    exact: true,
    RouteComponent: LocationManagement,
    active: true,
  },
  /* End */
  /* My SDS */
  {
    path: '/my-sds',
    key: 'my-sds',
    exact: true,
    RouteComponent: MySds,
    active: true,
  },
  {
    path: '/my-sds/location-detail/:locationId',
    key: 'my-sds',
    exact: true,
    RouteComponent: MySds,
    active: true,
  },
  {
    path: '/my-sds/sds-detail/:pdfId?',
    key: 'my-sds',
    exact: true,
    RouteComponent: MySds,
    active: true,
  },
  {
    path: '/my-sds/substance-detail/:substanceId?',
    key: 'my-sds',
    exact: true,
    RouteComponent: MySds,
    active: true,
  },
  /* End */
  /* Substance on Location */
  /* We are moving these routes to /my-sds -> Remove after testing */
  {
    path: '/substances-on-location',
    key: 'substances-on-location',
    exact: true,
    RouteComponent: SubstanceOnLocation,
    active: false,
    props: {
      admin: false,
    },
  },
  {
    path: '/substances-on-location/sds-detail/:pdfId?',
    key: 'substances-on-location',
    exact: true,
    RouteComponent: SubstanceOnLocation,
    active: false,
    props: {
      admin: false,
    },
  },
  {
    path: '/substances-on-location/location-detail/:locationId',
    key: 'substances-on-location',
    exact: true,
    RouteComponent: SubstanceOnLocation,
    active: false,
    props: {
      admin: false,
    },
  },
  /* End */
  /* Locations */
  {
    path: '/locations',
    key: 'locations',
    exact: true,
    RouteComponent: Locations,
    active: true,
    permission: PERMISSION.ACCESS_TO_MY_LOCATIONS_PAGE,
  },
  /* End */
  /* All our SDS */
  {
    path: '/all-our-sds',
    key: 'all-our-sds',
    exact: true,
    RouteComponent: AllOurSds,
    active: true,
    permission: PERMISSION.ACCESS_TO_ALL_OUR_SDS_PAGE,
  },
  {
    path: '/all-our-sds/substance-detail/:substanceId?',
    key: 'all-our-sds',
    exact: true,
    RouteComponent: AllOurSds,
    active: true,
    permission: PERMISSION.ACCESS_TO_ALL_OUR_SDS_PAGE,
  },
  /* End */
  /* SDS with newer version */
  {
    path: '/sds-with-newer-version/',
    key: 'sds-with-newer-version',
    exact: true,
    RouteComponent: SdsWithNewerVersion,
    active: true,
    permission: PERMISSION.ACCESS_TO_NEWER_VERSION_DATA,
  },
  {
    path: '/sds-with-newer-version/substance-detail/:substanceId?',
    key: 'sds-with-newer-version',
    exact: true,
    RouteComponent: SdsWithNewerVersion,
    active: true,
    props: {
      pdfDetail: true,
    },
    permission: PERMISSION.ACCESS_TO_NEWER_VERSION_DATA,
  },
  {
    path: '/sds-with-newer-version/diff/:substanceId?',
    key: 'sds-with-newer-version',
    exact: true,
    RouteComponent: SdsWithNewerVersion,
    active: true,
    props: {
      diff: true,
    },
  },
  /* End */
  /* Unmatched substances */
  {
    path: '/unmatched-substances',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/substances/SDSRequests'));
    }),
    active: true,
    permission: PERMISSION.ACCESS_SUBSTANCE_WITH_MISSING_SDS_PAGE,
  },
  /* End */
  /* Hazardous substances */
  {
    path: '/hazardous-substances',
    key: 'hazardous-substances',
    exact: true,
    RouteComponent: HazardousSubstance,
    active: true,
    permission: PERMISSION.ACCESS_HAZARDOUS_SUBSTANCES_PAGE,
  },
  {
    path: '/hazardous-substances/substance-detail/:substanceId?',
    key: 'hazardous-substances',
    exact: true,
    RouteComponent: HazardousSubstance,
    active: true,
    permission: PERMISSION.ACCESS_HAZARDOUS_SUBSTANCES_PAGE,
  },
  /* End */
  /* archived */
  {
    path: '/archived',
    key: 'archived',
    exact: true,
    RouteComponent: ArchivePage,
    permission: PERMISSION.ACCESS_TO_ARCHIVE_PAGE,
    active: true,
  },
  {
    path: '/archived/substance-detail/:substanceId?',
    key: 'archived',
    exact: true,
    RouteComponent: ArchivePage,
    active: true,
    props: {
      substanceTab: true,
    },
    permission: PERMISSION.ACCESS_TO_ARCHIVE_PAGE,
  },
  {
    path: '/archived/sds-detail/:sdsPdfId?',
    key: 'archived',
    exact: true,
    RouteComponent: ArchivePage,
    active: true,
    props: {
      substanceTab: true,
    },
    permission: PERMISSION.ACCESS_TO_ARCHIVE_PAGE,
  },
  /* End */
  /* Global sds search */
  {
    path: '/global-sds-search',
    key: 'global-sds-search',
    exact: true,
    RouteComponent: GlobalSdsSearch,
    active: true,
  },
  {
    path: '/global-sds-search/sds-detail/:pdfId?',
    key: 'global-sds-search',
    exact: true,
    RouteComponent: GlobalSdsSearch,
    active: true,
  },
  /* End */
  {
    path: '/account',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/accounts/account-settings-page/AccountSettingsPage')
      );
    }),
    active: true,
  },
  {
    path: '/billing/',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/administration/billing/Billing'));
    }),
    active: true,
    needFetchUser: true,
  },
  {
    path: '/payment/',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/administration/payment-status/PaymentStatus')
      );
    }),
    active: true,
  },
  {
    path: '/administration/organization/',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import(
          'pages/administration/organization-settings-page/OrganizationSettingsPage'
        )
      );
    }),
    active: true,
    permission: PERMISSION.ACCESS_TO_ORGANIZATION_PAGE,
  },
  {
    path: '/administration/subscription/',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/administration/subscription/Subscription')
      );
    }),
    active: true,
    needFetchUser: true,
    permission: PERMISSION.ACCESS_TO_SUBSCRIPTION_PAGE,
  },
  {
    path: '/location-management/import-status/',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/location-management/import-status'));
    }),
    active: true,
    permission: PERMISSION.ACCESS_TO_IMPORT_STATUS_PAGE,
  },
  {
    path: '/administration/import-product-list/',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/administration/import-product-list/ImportProductListPage')
      );
    }),
    active: true,
    permission: PERMISSION.IMPORT_PRODUCT_LIST,
  },
  /* User management */
  {
    path: '/administration/user-management/:action?',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/administration/user-management/UserManagement')
      );
    }),
    active: true,
    permission: PERMISSION.ACCESS_TO_USER_MANAGEMENT_PAGE,
  },
  {
    path: '/administration/qr-code-management/',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/administration/user-management/UserManagement')
      );
    }),
    active: true,
    permission: PERMISSION.ACCESS_TO_QR_CODE_MANAGEMENT_PAGE,
    props: {
      isQrCodeManagement: true,
    },
  },
  {
    path: '/administration/user-management/user/:selectedUserId',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/administration/user-management/UserLocationAccess')
      );
    }),
    active: true,
    permission: PERMISSION.ACCESS_TO_USER_MANAGEMENT_PAGE,
  },
  /* End */
  /* Work procedures */
  {
    path: '/my-work-procedures',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/my-work-procedures/MyWorkProcedures'));
    }),
    active: true,
    customerPermission: PERMISSION.ACCESS_TO_MY_WORK_PROCEDURE_PAGE,
  },
  {
    path: '/work-procedures',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/work-procedures/WorkProcedures'));
    }),
    active: true,
    permission: PERMISSION.WORK_PROCEDURE,
  },
  {
    path: '/work-procedures/:workProcedureId/summary',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import(
          'pages/work-procedures/job-analysis-summary-v2/JobAnalysisSummaryV2'
        )
      );
    }),
    active: true,
    permission: PERMISSION.WORK_PROCEDURE,
  },
  {
    path: '/work-procedures/:workProcedureId',
    key: 'work-procedure-detail',
    exact: true,
    RouteComponent: WorkProcedureDetail,
    active: true,
    permission: PERMISSION.WORK_PROCEDURE,
  },
  {
    path: '/work-procedures/:workProcedureId/revision/:revisionId',
    key: 'work-procedure-detail',
    exact: true,
    RouteComponent: WorkProcedureDetail,
    active: true,
    permission: PERMISSION.WORK_PROCEDURE,
  },
  {
    path: '/work-procedures/:workProcedureId/report',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import(
          'pages/work-procedures/job-coshh-report-v2/JobAnalysisCoshhReport'
        )
      );
    }),
    active: true,
    permission: PERMISSION.WORK_PROCEDURE,
  },
  /* End */
  /* Risk Assessment */
  {
    path: '/risk-evaluations/:substanceId',
    key: 'substance-risk-evaluation-detail',
    exact: true,
    RouteComponent: RiskEvaluation,
    active: true,
    permission: PERMISSION.RISK_ASSESSMENT,
  },
  {
    path: '/risk-evaluations/:substanceId/hazard/:hazardId/diagram',
    key: 'substance-risk-evaluation-detail',
    exact: true,
    RouteComponent: RiskEvaluation,
    active: true,
    permission: PERMISSION.RISK_ASSESSMENT,
  },
  {
    path: '/risk-assessments/',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/risk-assessments/RiskAssessments'));
    }),
    active: true,
    permission: PERMISSION.RISK_ASSESSMENT,
  },
  {
    path: '/risk-evaluations/:substanceId/instructions',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import(
          'pages/work-procedures/job-analysis-chemical-instructions-v2/JobAnalysisChemicalInstructions'
        )
      );
    }),
    active: true,
    permission: PERMISSION.RISK_ASSESSMENT,
  },
  /* End */
  /* Substitution */
  {
    path: '/substitutions',
    key: 'substitutions',
    exact: true,
    RouteComponent: Substitutions,
    active: true,
    permission: PERMISSION.SUBSTITUTION,
  },
  {
    path: '/substitutions/create',
    key: 'create-substitution',
    exact: true,
    RouteComponent: CreateSubstitution,
    active: true,
    permission: PERMISSION.SUBSTITUTION,
  },
  /* End */
  /* Exposure */
  {
    path: '/exposures',
    key: 'exposures',
    exact: true,
    RouteComponent: Exposures,
    active: true,
    permission: PERMISSION.EXPOSURE,
  },
  {
    path: '/my-exposures',
    key: 'my-exposures',
    exact: true,
    RouteComponent: MyExposures,
    active: true,
    permission: PERMISSION.ACCESS_TO_MY_EXPOSURE_PAGE,
  },
  {
    path: '/exposures/create',
    key: 'create-exposure',
    exact: true,
    RouteComponent: CreateExposure,
    active: true,
    permission: PERMISSION.EXPOSURE,
  },
  {
    path: '/exposures/:exposureId',
    key: 'exposure-details',
    exact: true,
    RouteComponent: ExposureDetails,
    active: true,
    permission: PERMISSION.EXPOSURE,
  },
  /* End */
  {
    path: '/exportExcel',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import(
          'pages/sys-tools/export-excel-localization/ExportExcelLocalization'
        )
      );
    }),
    active: true,
  },
  {
    path: '/importExcel',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import(
          'pages/sys-tools/import-excel-localization/ImportExcelLocalization'
        )
      );
    }),
    active: true,
  },
  {
    path: '/administration/setting-function',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/administration/settings/SettingFunction')
      );
    }),
    active: true,
    permission: PERMISSION.ACCESS_TO_SETTING_FUNCTION_PAGE,
  },
  {
    path: '/splitter',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/validator-sds/splitter'));
    }),
    active: true,
  },
  {
    path: '/reports',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/reports/SubstancesReport'));
    }),
    active: true,
    permission: PERMISSION.REPORTS,
  },
  {
    path: '/go-live-checklist',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/go-live-checklist/GoLiveCheckList'));
    }),
    active: true,
    permission: PERMISSION.ACCESS_TO_GO_LIVE_CHECKLIST_PAGE,
  },
  {
    path: '/match-sds',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/substances/find-sds/FindSDS'));
    }),
    active: true,
  },
  {
    path: '/publicView/:token',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/pdf-view/public-pdf-view/PublicPDFView')
      );
    }),
    active: true,
  },
  {
    path: '/pdfBookletView/:token',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/pdf-view/booklet-pdf-view'));
    }),
    active: true,
  },
  {
    path: '/location/:id/binder',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/location-management/location-binder/LocationBinderPage')
      );
    }),
    active: true,
  },
  {
    path: '/pdfLabeling',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/pdf-labeling/PDFLabelingPageView'));
    }),
    active: true,
  },
  {
    path: '/import-zip-to-root-location',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/public-view/import-zip-to-root-location')
      );
    }),
    active: true,
  },
];

export const defaultRoutes: Array<RouteInterface> = [
  {
    path: '/onesignin/:userId/:token',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/accounts/sign-up/OneSignIn'));
    }),
    active: true,
  },
  {
    path: '/login',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/accounts/login/Login'));
    }),
    active: true,
  },
  {
    path: '/service-unavailable',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/accounts/service-unavailable/ServiceUnavailable')
      );
    }),
    active: true,
  },
  {
    path: '/publicInstructions/',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import(
          'pages/work-procedures/job-analysis-chemical-instructions-v2/JobAnalysisChemicalInstructions'
        )
      );
    }),
    active: true,
  },
  {
    path: '/sso-login',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/sso-login/SSOLoginPage'));
    }),
    active: true,
  },
  {
    path: '/request-login-link',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/accounts/login-link/RequestLoginLinkPage')
      );
    }),
    active: true,
  },
  {
    path: '/2fa/verify-code',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/accounts/verify-2fa-code/Verify2FACode')
      );
    }),
    active: true,
  },
  {
    path: '/2fa/send-code',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/accounts/verify-2fa-code/Verify2FASendCode')
      );
    }),
    active: true,
  },
  {
    path: '/login/qr-scan',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/accounts/qr-login-scan/QrLoginScan'));
    }),
    active: true,
  },
  {
    path: '/signUp/:plan?',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/accounts/sign-up/SignUp'));
    }),
    active: true,
  },
  {
    path: '/reset-password',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(import('pages/accounts/reset-password/ResetPassword'));
    }),
    active: true,
  },
  {
    path: '/reset-password/:userId/:token',
    exact: true,
    RouteComponent: lazy((): Promise<any> => {
      return deferImport(
        import('pages/accounts/reset-password/SetPasswordPage')
      );
    }),
    active: true,
  },
];
