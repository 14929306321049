import { lazy, Suspense, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import i18next, { supportedLanguages } from 'utils/i18n';
import { showHeader } from 'store/pageAction/pageAction';
import { LanguageProvider } from 'pages/LanguageProvider';
import { getUrlLanguage } from 'utils/i18n';
import Layout from 'components/layout/Layout';
import {
  checkUserExpire,
  clearUserSessionFromStorage,
  deferImport,
  setQRLoginTokenToStorage,
  setUserSessionToStorage,
} from 'utils/helper';
import { validateToken } from 'utils';
import { isTokenAvailable } from 'utils/validateToken';
import { updateLastLogin } from 'api/auth/patch';
import UserInterface from 'interfaces/UserInterface';
import { handleRedirectLogin } from 'api/axios-client';
import ValidateSubscriptionPlan from 'components/midlewares/ValidateSubscriptionPlan';
import CustomLoader from 'components/loader/Loader';
import {
  defaultRoutes,
  guardRoutes,
  RouteInterface,
} from 'routers/routes-data';
import PermissionRoute from './permission-route';
import {
  getMoreSubstancesCount,
  getNewerSDSCount,
  fetchUser as fetchUserThunk,
} from 'services/user/thunks';
import {
  setSystemColumns,
  setCustomColumns,
  setDefaultLocation,
  setAddSdsGuideCompleted,
  setNotFindSdsGuideCompleted,
  setUserPermission,
  setCustomerPermission,
} from 'services/user/slice';
import {
  defaultLocationSelector,
  userDataSelector,
} from 'services/user/selectors';
import {
  FetchUserResponseDto,
  UserLocationAccess,
} from 'interfaces/administration/UserInterface';
import { LOCATION_ROLE, USER_ROLE } from 'enums/role.enums';
import { Helmet } from 'react-helmet';
import { scanningQRSessionGetRefreshToken } from 'api/auth/post';
import { AppDispatch } from 'constant';
import { fetchLocationStructure } from 'services/location/thunk';
import { fetchPermission } from 'services/permission/thunks';

/* Lazy load component */
const AuthenticatingPopup = lazy((): Promise<any> => {
  return deferImport(
    import('components/loader/authenticating-popup/AuthenticatingPopup')
  );
});
const NotFound404 = lazy((): Promise<any> => {
  return deferImport(import('pages/404'));
});
/* End */

function Routes() {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(userDataSelector);
  const defaultLocationID = useSelector(defaultLocationSelector);
  const [authenticating, setAuthenticating] = useState<boolean>(true);
  const location = window.location.pathname;
  const urlParams = new URLSearchParams(window.location?.search);

  /* Fetch user */
  const authenticateUser = async (): Promise<UserInterface | null> => {
    const userResponse = await dispatch(fetchUserThunk());

    if (!fetchUserThunk.fulfilled.match(userResponse)) return null;

    if (userResponse.payload) {
      const userData: UserInterface = userResponse.payload;
      const languageCode = userData.language_code;
      if (languageCode) {
        i18next.changeLanguage(languageCode);
      }
      return userData;
    }

    return null;
  };

  /* Fetch location structure */
  const fetchLocations = async (default_location: string | number | null) => {
    const initLocationAction = await dispatch(fetchLocationStructure());
    if (
      fetchLocationStructure.fulfilled.match(initLocationAction) &&
      !defaultLocationID &&
      initLocationAction.payload &&
      initLocationAction.payload.all.length > 0
    ) {
      const location_access_list =
        initLocationAction.payload.locations_access ?? [];
      if (
        location_access_list.filter(
          (item: UserLocationAccess) =>
            !(item.role === LOCATION_ROLE.NO_ACCESS_ROLE) &&
            item.department_id === default_location &&
            !item.is_archived
        ).length > 0
      )
        dispatch(setDefaultLocation(default_location));
      else {
        const firstLocation = initLocationAction.payload.all[0];
        dispatch(setDefaultLocation(firstLocation.id));
      }
    }
    /* Close authenticating modal */
    setAuthenticating(false);
  };

  /* Authorize user information & fetching other related data */
  const authorizeUser = (userData: FetchUserResponseDto) => {
    /* Validate user plan */
    ValidateSubscriptionPlan(userData as UserInterface, dispatch);

    dispatch(
      setSystemColumns(
        userData.inventory_manager_settings.additional_data_columns_v2 ?? []
      )
    );
    dispatch(
      setCustomColumns(
        userData.inventory_manager_settings.additional_custom_columns ?? []
      )
    );
    dispatch(
      setAddSdsGuideCompleted(
        userData.inventory_manager_settings.add_sds_guide_completed
      )
    );
    dispatch(
      setNotFindSdsGuideCompleted(
        userData.inventory_manager_settings.not_find_sds_guide_completed
      )
    );
    dispatch(setUserPermission(userData.inventory_manager_role_permissions));
    dispatch(
      setCustomerPermission(
        userData.customer?.inventory_subscription_plan
          ?.inventory_manager_permissions
          ? userData.customer.inventory_subscription_plan
              .inventory_manager_permissions
          : []
      )
    );
    // dispatch(setLocationAccess(userData?.locations_access ?? []));
    let default_location: string | number | null = null;
    if (!defaultLocationID) {
      default_location = userData.inventory_manager_settings?.default_location;
      if (userData.inventory_manager_role === USER_ROLE.OWNER) {
        dispatch(setDefaultLocation(default_location));
      }
    }

    /* Fetching other data */
    if (!checkUserExpire(userData)) {
      /* Fetch location structure */
      fetchLocations(default_location);
      /* Fetch permission list */
      dispatch(fetchPermission());
      /* Fetch notification count */
      dispatch(getNewerSDSCount());
      dispatch(getMoreSubstancesCount());
    }
  };

  const initHotjar = (userData: UserInterface) => {
    const isActiveHotjar = () => {
      const userCustomerData = userData.customer;
      if (!userCustomerData.created_date) return false;
      const createdDate = new Date(userCustomerData.created_date);
      const currentDate = new Date();
      const createdDays =
        (currentDate.getTime() - createdDate.getTime()) / 86400000; // convert timestamp to days
      // we only want hotjar to be active for two days after signup
      return createdDays <= 2;
    };
    if (isActiveHotjar()) {
      (function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 3482045, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  };

  /* Fetch user api */
  const fetchUser = async () => {
    setAuthenticating(true);
    const userData = await authenticateUser();
    if (!userData) {
      handleRedirectLogin();
      return;
    }

    authorizeUser(userData as FetchUserResponseDto);

    setAuthenticating(false);

    return new Promise(resolve => {
      resolve(userData);
    });
  };

  /* Get current language */
  const getLang = (): string => {
    let lang = 'en';
    let urlLang = getUrlLanguage();
    let userLang = user?.language_code;

    if (urlLang && supportedLanguages.indexOf(urlLang) > -1) {
      lang = urlLang;
    }

    if (
      !lang &&
      userLang !== undefined &&
      supportedLanguages.indexOf(userLang) > -1
    ) {
      lang = userLang;
    }

    return lang;
  };

  /* Other login method */
  const handleRouterExternal = async () => {
    /* One sign in Method check */
    const token = urlParams.get('token');
    if (token && validateToken(token)) {
      clearUserSessionFromStorage();
      setUserSessionToStorage('website', token, undefined);
      updateLastLogin();
      return;
    }

    /* QR login method check */
    const qr_login_token = urlParams.get('qr_login_token');
    if (qr_login_token && validateToken(qr_login_token)) {
      setQRLoginTokenToStorage(qr_login_token);
      // const loginQrCodeRes = await loginQrCode(qr_login_token);
      // if (loginQrCodeRes && loginQrCodeRes.status === 200) {
      //   setUserSessionToStorage(
      //     'qrcode',
      //     loginQrCodeRes.data.refresh_token,
      //     loginQrCodeRes.data.access_token
      //   );

      //   localStorage.setItem('user_email', loginQrCodeRes.data?.email);
      // } else {
      //   clearUserSessionFromStorage();
      // }
    }

    /* Mobile temporary session check */
    const mobile_session_token = urlParams.get('access_token');
    if (mobile_session_token && validateToken(mobile_session_token)) {
      const getScanningQRSession = await scanningQRSessionGetRefreshToken(
        mobile_session_token
      );

      if (getScanningQRSession) {
        if (getScanningQRSession.status === 200) {
          const tokenData = getScanningQRSession.data;
          setUserSessionToStorage(
            'website',
            tokenData.refresh_token,
            mobile_session_token
          );
          return;
        }
        handleRedirectLogin();
      }
    }

    return;
  };

  /* Check public url */
  const handleGuardPublic = () => {
    /* Custom public URL */
    const isRouterPublic =
      (location.includes('sdsDetails') && urlParams.get('allowView')) ||
      location.includes('publicInstructions') ||
      location.includes('pdfBookletView') ||
      location.includes('scanner') ||
      location.includes('splitter');
    /* Authenticated URL */
    const isAuthenticatedPage = [
      '/login',
      '/signup',
      '/reset-password',
      '/onesignin',
      '/2fa/',
      '/sso-login',
      '/service-unavailable',
    ].some(item => {
      return location.toLowerCase().indexOf(item) > -1;
    });

    if (isAuthenticatedPage) dispatch(showHeader(false));
    else dispatch(showHeader(true));

    return !!(isRouterPublic || isAuthenticatedPage);
  };

  const lang = getLang();

  useEffect(() => {
    const init = async () => {
      /* Initially check custom login approach: One sign-in & QR login */
      await handleRouterExternal();

      /* Cancel fetching api when in public url */
      if (handleGuardPublic()) {
        if (location.toLowerCase().indexOf('/service-unavailable') > -1) {
          const userData = await authenticateUser();
          if (!userData) {
            handleRedirectLogin();
            return;
          }
        }
        setAuthenticating(false);
        return;
      }

      /* Validate token -> Cancel before fetching user */
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
      if (
        (!accessToken ||
          !validateToken(accessToken) ||
          !isTokenAvailable(accessToken)) &&
        (!refreshToken ||
          !validateToken(refreshToken) ||
          !isTokenAvailable(refreshToken))
      ) {
        handleRedirectLogin();
        return;
      }

      /* Initially fetch user data */
      const userData = await authenticateUser();
      if (!userData) {
        handleRedirectLogin();
        return;
      }

      /* Initially Hotjar */
      initHotjar(userData);

      /* Authenticate user information */
      authorizeUser(userData as FetchUserResponseDto);

      if (
        !location.includes('location-management') &&
        !location.includes('my-sds')
      ) {
        /* Close authenticating modal */
        setAuthenticating(false);
      }
    };

    init();
  }, []);

  const page = (
    <Switch>
      {guardRoutes.map((item: RouteInterface) => {
        const {
          path,
          key,
          exact,
          RouteComponent,
          active,
          permission,
          customerPermission,
          pageTitle,
          props,
          needFetchUser,
        } = item;

        if (!active) return null;

        return (
          <Route key={key ?? path} exact={exact} path={path}>
            <Helmet
              title={
                key === 'my-sds'
                  ? `${i18next.t('common:sds_library_for')} ${
                      user?.customer?.name
                    }`
                  : pageTitle ?? 'SDS Manager - Inventory Management'
              }
            />
            <PermissionRoute
              permission={permission}
              customerPermission={customerPermission}
              key={key ?? path}
              user={user}
            >
              {needFetchUser ? (
                <RouteComponent {...props} fetchUser={fetchUser} />
              ) : (
                <RouteComponent {...props} />
              )}
            </PermissionRoute>
          </Route>
        );
      })}

      {defaultRoutes.map((item: RouteInterface) => {
        const { path, key, exact, RouteComponent, active, pageTitle, props } =
          item;

        if (!active) return null;

        return (
          <Route key={key ?? path} exact={exact} path={path}>
            <Helmet
              title={
                key === 'my-sds'
                  ? `${i18next.t('common:sds_library_for')} ${
                      user?.customer?.name
                    }`
                  : pageTitle ?? 'SDS Manager - Inventory Management'
              }
            />
            <RouteComponent {...props} />
          </Route>
        );
      })}

      <Route path="/" component={NotFound404} />
    </Switch>
  );

  return (
    <LanguageProvider language={lang}>
      {authenticating ? (
        <Suspense fallback={<CustomLoader />}>
          <AuthenticatingPopup />
        </Suspense>
      ) : (
        <Layout user={user}>
          <Suspense fallback={<CustomLoader />}>{page}</Suspense>
        </Layout>
      )}
    </LanguageProvider>
  );
}

export default Routes;
