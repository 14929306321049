import React from 'react';
import { useTranslation } from 'react-i18next';
import { get, getMyExposures } from 'api';
import CustomLoader from 'components/loader/Loader';
import SidebarLayout from 'components/layout/SidebarLayout';
import useStyles from './styles';
import {
  ExposuresResponse,
  MyExposuresFiltersChoices,
  MY_EXPOSURE_STATUS,
  DEFAULT_MY_EXPOSURE_STATUS_OPTION,
  MY_EXPOSURE_STATUS_OPTIONS,
} from 'interfaces/exposure';
import MultiSelectFilter, {
  MultiSelectFilterOption,
} from 'components/commons/select-filter/MultiSelectFilter';
import MyExposuresTable from 'components/exposures/my-exposures';

const MyExposures = () => {
  const { t } = useTranslation(['exposures']);
  const classes = useStyles();
  const [exposuresResponse, setExposuresResponse] =
    React.useState<ExposuresResponse>();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const [filtersLoading, setFiltersLoading] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<MyExposuresFiltersChoices>({
    work_procedures: [],
  });
  const [selectedWorkProcedures, setSelectedWorkProcedures] = React.useState<
    Array<string>
  >([]);
  const [selectedStatuses, setSelectedStatuses] = React.useState<
    Array<MultiSelectFilterOption<MY_EXPOSURE_STATUS, MY_EXPOSURE_STATUS>>
  >(DEFAULT_MY_EXPOSURE_STATUS_OPTION);

  const fetchMyExposures = (): void => {
    setTableLoading(true);
    const getExposuresRequest = getMyExposures({
      page: page + 1,
      page_size: rowsPerPage,
      status: selectedStatuses.map((el) => el.value),
      work_procedure: selectedWorkProcedures,
    });
    getExposuresRequest.then((response: any) => {
      if (response.status === 200) {
        setTableLoading(false);
        setExposuresResponse(response.data);
      }
    });
  };

  const fetchMyExposuresFilters = (): void => {
    setFiltersLoading(true);
    const getExposuresRequest = get('/exposures/assigments/filters', {});
    getExposuresRequest.then((response) => {
      if (response.status === 200) {
        setFiltersLoading(false);
        setFilters(response.data);
      }
    });
  };

  const renderTable = (): React.ReactElement | null => {
    if (tableLoading) {
      return (
        <div className={classes.loadingWrapper}>
          <CustomLoader />
        </div>
      );
    }

    if (!exposuresResponse?.results) return null;

    return (
      <MyExposuresTable
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        count={exposuresResponse.count}
        rows={exposuresResponse.results}
        fetchExposures={fetchMyExposures}
      />
    );
  };
  const renderFilters = (): React.ReactElement | null => {
    return (
      <div className={classes.filtersWrapper}>
        <MultiSelectFilter
          options={MY_EXPOSURE_STATUS_OPTIONS}
          defaultValue={selectedStatuses}
          label={t('common:status')}
          onSelect={(
            value: Array<
              MultiSelectFilterOption<MY_EXPOSURE_STATUS, MY_EXPOSURE_STATUS>
            >
          ) => {
            setSelectedStatuses(value);
            setPage(0);
          }}
          translatedKey={'exposures'}
        />
        <MultiSelectFilter
          options={filters.work_procedures}
          label={t('sidebar:job_procedures')}
          loading={filtersLoading}
          disabled={!filters.work_procedures}
          onSelect={(value: Array<MultiSelectFilterOption<string, string>>) => {
            setSelectedWorkProcedures(value.map((el) => el.value));
            setPage(0);
          }}
        />
      </div>
    );
  };
  React.useEffect(() => {
    fetchMyExposures();
  }, [page, rowsPerPage, selectedWorkProcedures, selectedStatuses]);

  React.useEffect(() => {
    fetchMyExposuresFilters();
  }, []);

  return (
    <SidebarLayout>
      <div className={classes.substitutionsWrapper}>
        {renderFilters()}
        {renderTable()}
      </div>
    </SidebarLayout>
  );
};

export default MyExposures;
