import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { ListItem, ListItemIcon, Box, Typography } from '@mui/material';
import React from 'react';
import { ExpandMore, KeyboardArrowRight } from '@mui/icons-material';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { isMobile } from 'utils/helper';

interface MenuItemProps {
  content: string;
  isSelected: (value: string) => boolean;
  value: string;
  itemIcon: React.ReactElement;
  hasChildren?: boolean;
  childrenComponent?: React.ReactElement;
  isExpanded?: boolean;
  onClick?: () => void;
  badge?: React.ReactElement | null;
}

const MenuItem = ({
  content,
  isSelected,
  value,
  itemIcon,
  hasChildren,
  childrenComponent,
  isExpanded,
  onClick,
  badge,
}: MenuItemProps) => {
  const { t } = useTranslation(['sidebar']);
  const classes = useStyles();

  const onItemClick = () => {
    if (onClick !== undefined) onClick();
  };

  return (
    <>
      <ListItem
        className={`
          ${classes.menuItem} ${
          hasChildren ? classes.menuItemHasChildren : ''
        } ${isSelected(value) ? classes.menuActive : ''}
        `}
        button
        component="div"
        onClick={onItemClick}
      >
        <ListItemIcon style={{ minWidth: 'auto' }}>
          {itemIcon}
          {badge}
        </ListItemIcon>
        <Box className={classes.menuItemText} display="flex">
          <Typography component="span">{t(content)}</Typography>
          {hasChildren ? (
            isExpanded ? (
              <ExpandMore
                style={{
                  fontSize: 22,
                  color: 'rgba(0, 0, 0, 0.54)',
                }}
                className={classes.menuItemIcon}
              />
            ) : (
              <KeyboardArrowRight
                style={{
                  fontSize: 22,
                  color: 'rgba(0, 0, 0, 0.54)',
                }}
                className={classes.menuItemIcon}
              />
            )
          ) : null}
        </Box>
      </ListItem>
      {hasChildren && (
        <div
          className={`${classes.childrenItemListWrap} ${
            isExpanded ? classes.isExpand : ''
          }`}
        >
          <SimpleBar
            style={{
              maxHeight: 450,
            }}
            autoHide={isMobile() ? false : true} 
            classNames={{
              vertical: classes.vertical,
            }}
          >
            {childrenComponent}
          </SimpleBar>
        </div>
      )}
    </>
  );
};

export default MenuItem;
