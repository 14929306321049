import { FunctionComponent, ReactElement, useState, useRef } from 'react';
import { FormControl, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import { reportProblem } from 'api';
import { setReport } from 'store/pageAction/pageAction';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { renderSnackbar } from 'utils';
import { useTranslation } from 'react-i18next';
import DesktopDialog from 'components/commons/desktop-dialog';
import FormGrid from 'components/commons/form-grid';
import SubmitButton from 'components/commons/submit-button';
import { convertTooLongName, isMobile, prettyFileSize } from 'utils/helper';

interface AddReportPopupProps {
  defaultSubject?: string,
  openPopup?: boolean;
  onClose?: () => void
}

const AddReportPopup: FunctionComponent<AddReportPopupProps> = ({
  defaultSubject = '',
  openPopup,
  onClose
}): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation(['add_report_popup', 'sidebar']);

  const [fileList, setFileList] = useState<Array<any>>([]);
  const [updateFileList, setUpdateFileList] = useState<boolean>(false);
  const globalOpen = useSelector((state: any) => state.pageReducer.setReport);
  const refContainer = useRef(null);
  const dispatch = useDispatch();

  const open = openPopup !== undefined ? openPopup : globalOpen

  const handleSubmit = () => {
    return new Promise((resolve, reject) => {
      let formData: any = refContainer.current;
      const subject = formData?.subject.value;
      const message = formData?.message.value;

      if (message.trim().length === 0 || subject.trim().length === 0) {
        renderSnackbar([t('add_report_popup:please_describe_problem')]);
        reject(false);
        return;
      }

      const reportProblemRequest = reportProblem({
        subject: formData?.subject.value,
        message: formData?.message.value,
        files: fileList || null,
      });
      reportProblemRequest.then((response) => {
        if (response.status === 201) {
          dispatch(setReport(false));
          renderSnackbar([t('add_report_popup:message_is_sent')]);
          setFileList([]);
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  };

  const handleSelectedFile = (e: {
    target: any;
    preventDefault: () => void;
  }) => {
    e.preventDefault();
    if (!e.target.files[0]) return;

    const files = e.target.files;
    let newFileList = Array<any>();
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      newFileList.push(file);
    }
    setFileList(newFileList);

  };

  const removeFileOnFileList = (index: number) => {
    fileList.splice(index, 1);
    setUpdateFileList(!updateFileList);
  }

  const renderFileList = () => {
    return (
      <div className={classes.fileListWrapper}>
        {fileList.map((file: any, idx: number) => {
          return (
            <div key={idx} className={classes.fileItem}>
              <b>{convertTooLongName(file.name)}</b>
              <span className={classes.fileSizeText}>{`(${prettyFileSize(file.size)})`}</span>
              <ClearIcon
                onClick={() => {
                  removeFileOnFileList(idx);
                }}
                className={classes.clearIcon}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DesktopDialog
        open={open}
        onClose={() => {
          setFileList([]);
          dispatch(setReport(false));
          onClose && onClose()
        }}
        title={t('common:contact_us')}
        actions={
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <SubmitButton onSave={handleSubmit} form="addReport">
              {' '}
              <SendIcon style={{ fill: '#626DF9' }} />{' '}
            </SubmitButton>
          </div>
        }
      >
        <form
          id="addReport"
          name="addReport"
          ref={refContainer}
          onClick={(e) => e.stopPropagation()}
        >
          <FormGrid>
            <TextField
              margin="dense"
              id="subject"
              defaultValue={defaultSubject}
              label={`${t('add_report_popup:subject')}`}
              type="text"
              fullWidth
              required
              variant='standard'
            />
            <div style={{ width: isMobile() ? 'initial' : '550px' }}>
              <TextField
                multiline
                minRows={5}
                placeholder={t('add_report_popup:contact_us_placeholder')}
                margin="dense"
                id="message"
                type="text"
                fullWidth
                variant="outlined"
                required
                inputProps={{ maxLength: 1000 }}
              />
            </div>
            <FormControl>
              <input
                style={{ display: 'none' }}
                id="inputFile"
                multiple
                type="file"
                onChange={handleSelectedFile}
              />
              <label
                style={{
                  width: 160,
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'Montserrat, sans-serif;',
                }}
                htmlFor="inputFile"
              >
                <CloudUploadIcon
                  style={{ fill: '#626DF9', cursor: 'pointer' }}
                />{' '}
                <span className={classes.uploadFileText}>
                  {t('add_report_popup:upload_file').toUpperCase()}
                </span>
              </label>

              {fileList && renderFileList()}

            </FormControl>
          </FormGrid>
        </form>
      </DesktopDialog>
    </div>
  );
};

export default AddReportPopup;
