import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'constant';
import { HomeState } from './slice';
import { GetStatisticsResponseDto } from 'interfaces/home';

export const statisticsSelector = createSelector<
  [(state: RootState) => HomeState],
  GetStatisticsResponseDto | null
>([state => state.home], home => home.statistics);

export const statisticsLoadingSelector = createSelector<
  [(state: RootState) => HomeState],
  boolean
>([state => state.home], home => home.statisticsLoading);

export const hideAllPanelsSelector = createSelector<
  [(state: RootState) => HomeState],
  boolean
>([state => state.home], home => home.hideAllPanels);
